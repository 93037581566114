import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import departementData from "./DepartementsData";
import { FormGroup, Modal, ModalBody, Form, Card, CardBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  TooltipComponent,
} from "../../components/Component";
import { UncontrolledTooltip } from "reactstrap";
import { useForm } from "react-hook-form";
import { DepartementContext } from "./DepartementsContext";
import getTextColor from "../../components/custom/getTextColor";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import {
  deleteDepartment,
  getDepartments,
  getDepartmentsAndOrder,
  getDepartmentsByField,
  postDepartment,
  putDepartment,
} from "../../services/departments";
import moment from "moment";
import ColorPicker from "react-best-gradient-color-picker";
import { DefaultRoleFunctions } from "data/DefaultRoleFunctions";

const Departements = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);

  const [dataTable, setDataTable] = useState(departementData);
  const [cookies, setCookie] = useCookies(["user"]);
  const { contextData } = useContext(DepartementContext);
  const [data, setData] = contextData;
  const [sm, updateSm] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });

  const [editContent, setEditContent] = useState(false);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["departments"].access || [];
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    pc: null,
    color: "#000000",
  });
  const [metaData, setMetaData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [departmentList, setDepartmentList] = useState([]);
  const [orderBy, setOrderBy] = useState("DESC");
  const { errors, register, handleSubmit } = useForm();
  const refreshHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
      });
      setNonFixedHeight(tempHeight);
    }
  };
  const onSortClicked = (id, order) => {
    setOrderBy(order);
    refreshDataByField(id, 1, order);
  };
  const refreshData = async (page) => {
    const responseGetDepartments = await getDepartments(
      cookies.SIDikey,
      onSearchText,
      cookies.departmentIds,
      cookies.id,
      roleFunctions,
      10,
      page
    );
    if (new RegExp(/20+/).test(responseGetDepartments.status)) {
      const resultData = responseGetDepartments.data.data;
      setDepartmentList(resultData);
      const finaldata = [];
      for (let departement of resultData) {
        let createdBy = "";
        let updatedBy = "";

        // if (!roleFunctions.includes("Access_All_Dept")) {
        //   if (departement.id !== cookies.departmentIds) {
        //     continue;
        //   }
        // }

        if (departement.createdBy != null) {
          createdBy = departement.createdBy.firstName + " " + departement.createdBy.lastName;
        } else {
          createdBy = "-";
        }

        if (departement.updatedBy != null) {
          updatedBy = departement.updatedBy.firstName + " " + departement.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }

        const tempData = {
          id: departement.id,
          name: departement.name,
          color: departement.color,
          pc: departement.pc,
          createdAt: departement.createdAt,
          updatedAt: departement.updatedAt,
          createdBy: createdBy,
          updatedBy: updatedBy,
          active: true,
        };
        finaldata.push(tempData);
      }
      setData(finaldata);
      const meta = responseGetDepartments.data.meta;
      setMetaData(meta);
      refreshHeight();
    } else {
      console.log(responseGetDepartments);
    }
  };
  const refreshDataByField = async (field, pageNumber, order) => {
    const responseGetDepartments = await getDepartmentsAndOrder(
      cookies.SIDikey,
      onSearchText,
      cookies.departmentIds,
      cookies.id,
      roleFunctions,
      10,
      pageNumber,
      field,
      order
    );

    if (new RegExp(/20+/).test(responseGetDepartments.status)) {
      const resultData = responseGetDepartments.data;
      const finaldata = [];
      resultData.data.forEach((departement) => {
        let createdBy = "";
        let updatedBy = "";

        if (departement.createdBy != null) {
          createdBy = departement.createdBy.firstName + " " + departement.createdBy.lastName;
        } else {
          createdBy = "-";
        }

        if (departement.updatedBy != null) {
          updatedBy = departement.updatedBy.firstName + " " + departement.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }

        const tempData = {
          id: departement.id,
          name: departement.name,
          color: departement.color,
          pc: departement.pc,
          createdAt: departement.createdAt,
          updatedAt: departement.updatedAt,
          createdBy: createdBy,
          updatedBy: updatedBy,
          active: true,
        };
        finaldata.push(tempData);
      });
      setData(finaldata);
      refreshHeight();
    } else {
      console.log(responseGetDepartments);
    }
  };

  // unselects the data on mount
  useEffect(() => {
    refreshData();
    let newData;
    newData = dataTable.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, [onSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {}, []);

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = dataTable.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...dataTable]);
    }
  }, [onSearchText, setData]);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      id: "",
      name: "",
      pc: null,
      color: "",
      active: true,
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ detail: false, add: false });
    setEditContent(false);
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async (submitData) => {
    const { name, pc, color } = submitData;
    const data = {
      name: name,
      pc: parseInt(pc),
      color: color ?? formData.color,
    };

    const responsePostDepartement = await postDepartment(cookies.SIDikey, data);

    if (new RegExp(/20+/).test(responsePostDepartement.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        refreshData();
        resetForm();
        setModal({ detail: false }, { add: false });
        setEditContent(false);
      });
    } else if (responsePostDepartement.status === 409) {
      Swal.fire("Unsuccesfull Transasction", "PC ID already assigned.", "error");
    } else if (responsePostDepartement.status === 400){
      Swal.fire("Unsuccesfull Transasction", "Name Already used.", "error");      
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  // submit function to update a new item
  const onEditSubmit = async (submitData) => {
    const { name, pc, color } = submitData;
    const data = {
      name: name,
      pc: parseInt(pc),
      color: color ?? formData.color,
    };

    const responsePutDepartement = await putDepartment(formData.id, cookies.SIDikey, data);
    if (new RegExp(/20+/).test(responsePutDepartement.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        refreshData();
        resetForm();
        setModal({ detail: false }, { add: false });
        setEditContent(false);
      });
    } else if (responsePutDepartement.status === 409) {
      Swal.fire("Unsuccesfull Transasction", "PC ID already assigned.", "error");
    } else if (responsePutDepartement.status === 400){
      Swal.fire("Unsuccesfull Transasction", "Name Already used.", "error");      
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const onDeleteClick = (id, name) => {
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDeleteDepartement = await deleteDepartment(id, cookies.SIDikey);

        if (new RegExp(/20+/).test(responseDeleteDepartement.status)) {
          Swal.fire({
            icon: "success",
            title: "Delete Department Success",
            focusConfirm: false,
          }).then(() => {
            refreshData();
            resetForm();
            setModal({ detail: false }, { add: false });
            setEditContent(false);
          });
        } else if (responseDeleteDepartement.data.statusCode === 409) {
          Swal.fire("Delete Department Failed", "Department dipakai di page lain", "error");
        } else {
          console.log(responseDeleteDepartement);
          Swal.fire("Delete Department Failed", responseDeleteDepartement.data.message[0].detail, "error");
        }
      }
    });
  };

  const onDetailClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setFormData({
          id: item.id,
          name: item.name,
          pc: item.pc,
          color: item.color,
          active: item.active,
        });
        setModal({ detail: true, add: false });
        setEditedId(id);
      }
    });
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [activeTab, setActiveTab] = useState(cookies.departmentId);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Change Page
  const paginate = (pageNumber) => refreshData(pageNumber);
  return (
    <React.Fragment>
      <Head title="Department"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Department
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>This page is used to add Departments</p>
            </CardBody>
          </Card>

          <Block className="d-flex justify-content-end">
            <BlockHeadContent style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="form-control-wrap ">
                    <div className="form-icon form-icon-left">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="default-04"
                      placeholder="Search"
                      onInput={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }} className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" style={{ display: sm ? "flex" : "none" }}>
                  <ul className="nk-block-tools">
                    {!roleFunctions.includes("create") || (
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                          <i className="ni ni-plus"></i>
                          <span>Tambah Baru</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>

        <Block>
          <DataTable style={{ borderRadius: "10px" }} className="card-stretch">
            <Row>
              <Col md="4" lg="3" sm="5" xs="6" className="fixed-column border-right">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Nama Department
                        <Button id="departmentName" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="departmentName">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.name", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.name", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        PC Department
                      </div>
                      {/* <UncontrolledTooltip autohide={false} placement="top" target="departmentPc">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.pc", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.pc", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip> */}
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow size="md">
                              <span>{(metaData.page - 1) * metaData.take + (index + 1)}</span>
                            </DataTableRow>
                            <DataTableRow
                              noWrap
                              customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                            >
                              <span
                                className="badge rounded-pill text-bg-primary"
                                style={{
                                  background: item.color,
                                  color: getTextColor(item?.color || "#000"),
                                }}
                              >
                                {item.name}
                              </span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span>{item.pc ?? '-'}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
              <Col md="8" lg="9" sm="7" xs="6" className="nfixed-column">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <span className="sub-text">Warna</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text pl-4">Aksi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Modify At
                        <Button id="departmentModifyAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="departmentModifyAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.updatedAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.updatedAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Modify By
                        {/* <Button id="departmentModifyBy" size="xs">
                          <Icon name="sort" />
                        </Button> */}
                      </div>
                      {/* <UncontrolledTooltip autohide={false} placement="top" target="departmentModifyBy">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.updatedBy", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.updatedBy", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip> */}
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Created At
                        <Button id="departmentCreateAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="departmentCreateAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.createdAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.createdAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Created By
                        {/* <Button id="departmentCreateBy" size="xs">
                          <Icon name="sort" />
                        </Button> */}
                      </div>
                      {/* <UncontrolledTooltip autohide={false} placement="top" target="departmentCreateBy">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.createdBy", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.createdBy", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip> */}
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow noWrap ref={addToRefs}>
                              <span className="departement-circle" style={{ background: item.color }}></span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <ul className="nk-tb-actions table-action">
                                <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"detail" + item.id}
                                    icon="eye-fill"
                                    direction="top"
                                    text="Detail"
                                  />
                                </li>
                                {!roleFunctions.includes("update") || (
                                  <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"edit" + item.id}
                                      icon="edit-alt-fill"
                                      direction="top"
                                      text="Edit"
                                    />
                                  </li>
                                )}
                                {!roleFunctions.includes("delete") || (
                                  <li
                                    className="nk-tb-action"
                                    onClick={() => {
                                      onDeleteClick(item.id, item.name);
                                    }}
                                  >
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"suspend" + item.id}
                                      icon="trash-fill"
                                      direction="top"
                                      text="Hapus"
                                    />
                                  </li>
                                )}
                              </ul>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.updatedAt).format("MMM DD, YYYY, HH:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.updatedBy}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.createdAt).format("MMM DD, YYYY, HH:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.createdBy}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal
          isOpen={modal.add}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ add: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Department</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Profit Center ID (Web Astro)</label>
                      <input
                        className="form-control"
                        type="number"
                        name="pc"
                        defaultValue={formData.pc}
                        placeholder="Enter pc"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.pc && <span className="invalid">{errors.pc.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label className="form-label">Warna Department </label>
                      {/* <input
                        className="form-control form-control-color"
                        type="color"
                        name="color"
                        // defaultValue={formData.email}
                        ref={register({
                          required: "This field is required",
                        })}
                      /> */}
                      <ColorPicker
                        disabled={!editContent}
                        name="color"
                        value={formData.color}
                        onChange={(e) => setFormData({ ...formData, color: e })}
                      />

                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          outline
                          color="primary"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modal.detail}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ detail: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Detail Department</h5>
              <Block className="d-flex justify-content-end">
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        {roleFunctions.includes("update") && (
                          <li className="nk-block-tools-opt">
                            <Button
                              color="primary"
                              className={editContent ? "btn d-none" : ""}
                              onClick={() => setEditContent(true)}
                            >
                              Ubah
                            </Button>
                          </li>
                        )}
                        {roleFunctions.includes("delete") && (
                          <li className="nk-block-tools-opt">
                            <Button
                              color="danger"
                              outline
                              className="btn"
                              onClick={() => onDeleteClick(formData.id, formData.name)}
                            >
                              Hapus
                            </Button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </Block>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        disabled={!editContent}
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Profit Center ID (Web Astro)</label>
                      <input
                        className="form-control"
                        type="number"
                        name="pc"
                        disabled={!editContent}
                        defaultValue={formData.pc}
                        placeholder="Enter pc"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Warna Department </label>
                      <div
                        className={!editContent ? "" : "d-none"}
                        style={{
                          background: formData.color,
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <ColorPicker
                        className={!editContent ? "d-none" : ""}
                        value={formData.color}
                        onChange={(e) => setFormData({ ...formData, color: e })}
                      />

                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12" className={editContent ? "" : "d-none"}>
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          outline
                          color="primary"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Departements;
