import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import Pages from "../route/Index";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import classNames from "classnames";
import { socketIO } from "services/socket";
import { MessagesContext } from "contexts/messagesContext";
import { useCookies } from "react-cookie";
import { servicePutUsersOnline, servicePutUsersOffline } from "services/users";
import { ToastContainer, toast } from "react-toastify";
import { onMessageListener, requestForToken, subscribeFirebaseToken, unsubscribeFirebaseToken } from "services/firebase";
import { Icon } from "components/Component";

const Layout = () => {
  const [tokenFound, setTokenFound] = useState("");
  const [notification, setNotification] = useState({ title: "", body: "" });
  //Sidebar
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [statusOnlineUsers, setStatusOnlineUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newTicket, setNewTicket] = useState();
  const [statusMessages, setStatusMessages] = useState([]);
  const [newSales, setNewSales] = useState();
  const [themeState] = useState({
    main: "default",
    sidebar: "white",
    header: "white",
    skin: "light",
  });

  let history = useHistory();

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : ""
    }`;
  }, [themeState.skin]);

  useEffect(() => {
    const socketListener = (msg_raw) => {
     console.log("SOCKET MESSAGE :", msg_raw);

      if (!msg_raw) {
        return;
      }
      if(!(cookies.role.name === "SUPER ADMIN") && msg_raw.type && msg_raw.type === "signOut" && msg_raw.id && msg_raw.id === cookies.id ) {
        // MULTI DEVICE OFFLINE
        servicePutUsersOffline(cookies.SIDikey, cookies.id);
        unsubscribeFirebaseToken(cookies.SIDikey, tokenFound); 
        removeCookie("SIDikey");
        history.push("/auth-login");
        return
      }
      
      if ("sign" in msg_raw) {
        // LOGIN AND SIGN IN : ONLINE USERS
        msg_raw.socketType = 'SIGNIN';
        if (!!statusOnlineUsers.find((element) => element.id === msg_raw.id)) {
          const onlineStatus = statusOnlineUsers;
          const osNew = [];
          onlineStatus.forEach((el) => {
            if (el.id === msg_raw.id) {
              el.status = msg_raw.status;
            }
            osNew.push(el);
          });
          setStatusOnlineUsers((users) => [...osNew]);
        } else {
          setStatusOnlineUsers((users) => [...users, msg_raw]);
        }
        return;
      }

      if ("work" in msg_raw) {
        //ASSIGNED CHAT
        msg_raw.socketType = 'ASSIGN_CHAT';
        
        let nmr = {...msg_raw.message, isAssign: true}
        setMessages((messages) => [...messages, nmr]);
        return;
      }
      if ("fromMe" in msg_raw) {
        msg_raw.socketType = 'MESSAGE STATUS';
        //SENT MESSAGE USUALLY CHANGE STATUS
        if (!messages.some((msg) => msg.id === msg_raw?.id)) {
          setMessages((messages) => [...messages, msg_raw]);
        }
      }

      if (msg_raw.from === "CREATE_TICKET_SERVICE") {
        msg_raw.socketType = 'CREATE_TICKET';
        setNewTicket(msg_raw);
      }

      // ROTATOR
      if(Array.isArray(msg_raw) && msg_raw.length > 0 && msg_raw[0].from === "ROTATOR_CRON_SERVICE") {
        const uniqueMessages = Object.values(msg_raw.reduce((acc, msg) => (acc[msg.chatId] = acc[msg.chatId] || msg, acc), {}));
        setNewSales([...uniqueMessages]);
      }
    };

    const socketListenerforUpdateData = (msg_raw) => {
      if (!msg_raw) { 
        return;
      }
      if (msg_raw.from === "ROTATOR_CRON_SERVICE") {
        msg_raw.socketType = 'ROTATOR';
        setNewSales(msg_raw);
      }
    };

    socketIO.on("connect", async () => {
      setCookie("socketId", socketIO.id);
      const resp = await servicePutUsersOnline(cookies.SIDikey, cookies.id, socketIO.id);
      // PEMBERITAHUAN ONLINE, KE SALURAN
      socketIO.emit("admin", JSON.stringify({
        sign:true,
        type:"signIn", 
        id:resp.data.id, 
        firstName:resp.data.firstName,
        lastName:resp.data.lastName,
        status:"ONLINE"
      }));
    });

    const adminRoles = ['SUPER ADMIN', 'ADMIN', 'HEAD', 'CS', 'AUDITOR', 'SYSTEM ADMIN']
    console.log('ROLE', cookies.role.name);

    if (adminRoles.includes(cookies.role.name)) {
      socketIO.on("admin", socketListener);
    } else {
      socketIO.on(cookies.id, socketListener);
    }
  }, [socketIO]);

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!visibility) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [visibility]);

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener("load", viewChange);
  window.addEventListener("resize", viewChange);

  const sidebarClass = classNames({
    "nk-sidebar-mobile": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });

  const sendFirebaseInfo = async () => {
    if (tokenFound) {
      const responseFirebaseInfo = await subscribeFirebaseToken(cookies.SIDikey, tokenFound);
    }
  };

  useEffect(() => {
    requestForToken(setTokenFound);
    sendFirebaseInfo();
  }, [tokenFound]);

  function ToastDisplay() {
    return (
      <div className="d-flex align-items-center">
        <Icon name="bell toast-bell" />
        <div>
          <p>
            <b>{notification?.title}</b>
          </p>
          <p>{notification?.body}</p>
        </div>
      </div>
    );
  }

  const notify = () => toast(<ToastDisplay />);

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <MessagesContext.Provider
      value={{
        messagesValue: [messages, setMessages],
        statusValue: [statusMessages, setStatusMessages],
        onlineValue: [statusOnlineUsers, setStatusOnlineUsers],
        salesUpdate: [newSales],
        ticketUpdate: [newTicket],
      }}
    >
      <ToastContainer theme="colored" position="top-right" toastStyle={{ backgroundColor: "#f4bd0e" }} />
      <React.Fragment>
        <Head title="Loading" />
        <div className="nk-app-root">
          <div className="nk-main">
            <Sidebar
              sidebarToggle={toggleSidebar}
              fixed
              mobileView={mobileView}
              theme={themeState.sidebar}
              className={sidebarClass}
              setVisibility={setVisibility}
            />
            {visibility && mobileView && <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>}
            <div className="nk-wrap">
              <Header sidebarToggle={toggleSidebar} fixed setVisibility={setVisibility} theme={themeState.header} />

              <Pages />
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    </MessagesContext.Provider>
  );
};
export default Layout;
